import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UseTableCellProps } from "react-table";
import { AccountModal } from "../../components/account-modal/AccountModal";
import { useAuthentication } from "../../components/auth/AuthProvider";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { SearchBox } from "../../components/inputs/Search";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { useShowToast } from "../../hooks/showToast";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { RootState, useThunkDispatch } from "../../store";
import { updateFilters } from "../../store/account/accounts.slice";
import { loadAccounts } from "../../store/account/thunks/account-load.thunk";
import { keysLoadHandlers, reloadApiKey } from "../../store/keys/keys.slice";
import { debounce } from "../../utils/debounce";
import { KeyAccounts } from "./AddKeyAccounts";

const DATE_FORMAT = "yyyy-MM-dd";

export const ApiKey: FC = () => {
  const { keyId } = useParams();
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiInternal } = useAuthentication();
  const { workingKey, accounts, filters } = useSelector(
    (state: RootState) => state.keys
  );
  const {
    isOpen: isRemoveModalOpen,
    onOpen: openRemoveModal,
    onClose: closeRemoveModal,
  } = useDisclosure();
  const {
    isOpen: isOpenAddAccount,
    onOpen: onOpenAddAccount,
    onClose: onCloseAddAccount,
  } = useDisclosure();
  const { page, total } = filters;
  const PAGE_SIZE = 20;

  const [workingAccount, setWorkingAccount] = useState<AccountsData>();
  const tableHeaderBg = useColorModeValue("blue.50", "transparent");

  const onPageChange = useCallback(
    (page: number) => {
      dispatch(updateFilters({ ...filters, page }));
    },
    [filters, dispatch]
  );

  const handleRemoveModal = useCallback(
    (account: AccountsData) => {
      if (!keyId) return;
      setWorkingAccount(account);
      openRemoveModal();
    },
    [keyId, openRemoveModal, setWorkingAccount]
  );

  const onSearchChange = useCallback(
    (value?: string) => {
      dispatch(updateFilters({ search: value, page: 1 }));
    },
    [dispatch]
  );

  const handleOnCloseModal = useCallback(() => {
    setWorkingAccount(undefined);
    closeRemoveModal();
  }, [setWorkingAccount, closeRemoveModal]);

  const removeAccounts = useCallback(() => {
    if (!keyId || !workingAccount) return;

    const index = workingKey?.accounts.findIndex(
      (acc: any) => acc.id === workingAccount.id
    );

    if (index === undefined || index === -1) return;

    keyId &&
      apiInternal
        .updateApiKey(keyId, {
          name: workingKey?.name,
          accounts: workingKey?.accounts
            ?.filter((_: any, i: any) => i !== index)
            .map((a: { id: any }) => a.id),
        })
        .then(() => {
          thunkDispatch(reloadApiKey({ apiInternal, keyId }));
          showToast("success", "Account(s) successfully removed");
          thunkDispatch(keysLoadHandlers({ apiInternal, keyId }));
        })
        .catch(() =>
          showToast("error", "Something happened while adding account(s)")
        );

    closeRemoveModal();
  }, [
    apiInternal,
    showToast,
    thunkDispatch,
    workingAccount,
    closeRemoveModal,
    keyId,
    workingKey,
  ]);

  const debouncedSearchChange = debounce(onSearchChange, 300);

  const columns = useMemo<DataTableColumn<AccountsData>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: UseTableCellProps<AccountsData>) => {
          return <Text isTruncated>{data.row.original.name}</Text>;
        },
      },

      {
        Header: "Created",
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Text wordBreak="break-word" isTruncated>
            {format(new Date(data.row.original.created), DATE_FORMAT || "")}
          </Text>
        ),
      },

      {
        Header: "Actions",
        maxWidth: 100,
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Box w="100%" display="flex" justifyContent="flex-end" gap={2}>
            <Button
              size="xs"
              colorScheme="red"
              onClick={() => handleRemoveModal(data.row.original)}
            >
              Remove
            </Button>
          </Box>
        ),
      },
    ],
    [handleRemoveModal]
  );

  useEffect(() => {
    if (workingKey) return;
    thunkDispatch(reloadApiKey({ apiInternal, keyId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiInternal, keyId, thunkDispatch, workingKey]);

  useEffect(() => {
    thunkDispatch(keysLoadHandlers({ apiInternal, keyId }));
  }, [apiInternal, keyId, thunkDispatch]);

  useEffect(() => {
    thunkDispatch(loadAccounts({ apiInternal }));
  }, [filters, thunkDispatch, apiInternal]);

  return (
    <>
      <Stack w="100%">
        <Flex w="100%" justifyContent="space-between">
          <Heading size="lg" my={2}>
            {workingKey?.name}
          </Heading>
          <Flex justifyContent="flex-end" alignItems="center">
            <SearchBox
              searchPlaceholder="Search by Name or State"
              onInputChange={debouncedSearchChange}
            />
            <Button
              w="50%"
              size="md"
              mt={2}
              mr={4}
              ml={2}
              colorScheme="blue"
              onClick={onOpenAddAccount}
            >
              <Icon as={MdAdd} mr={1} /> Add Accounts
            </Button>
          </Flex>
        </Flex>
        <DataTable
          columns={columns}
          data={accounts}
          rest={{ w: "100%" }}
          tableHeaderProps={{ p: 2, bgColor: tableHeaderBg, borderRadius: 6 }}
        />

        <Flex flexWrap="wrap" justifyContent="space-between">
          <PageData total={total} currentPage={page} pageSize={PAGE_SIZE} />

          <Pager
            currentPage={page}
            totalPages={Math.ceil(total / PAGE_SIZE)}
            onPageChange={onPageChange}
          />
        </Flex>
        {workingAccount && isRemoveModalOpen && (
          <AccountModal
            isOpen={isRemoveModalOpen}
            title={`${workingAccount.name}`}
            onClose={handleOnCloseModal}
            contentProps={{ maxW: "2xl" }}
            content={
              <Box>
                <Text>Are you sure to remove this account?</Text>
                <Flex w="100%" mt={4} justifyContent="space-between">
                  <Button onClick={removeAccounts} colorScheme="red">
                    Remove
                  </Button>
                  <Button onClick={handleOnCloseModal}>Cancel</Button>
                </Flex>
              </Box>
            }
          />
        )}
      </Stack>
      {workingKey && (
        <KeyAccounts
          keyId={workingKey.id}
          isOpen={isOpenAddAccount}
          onClose={onCloseAddAccount}
        />
      )}
    </>
  );
};
