import {
  ApiAccountDetail,
  ApiAccountEventSummary,
  ApiAccountSubscription,
  ApiAccountUser,
  ApiKey,
  ApiDashboard,
  ApiFindAccountsOptions,
  ApiInvoiceInternal,
  ApiPagedResult,
  ApiPagingOptions,
  ApiProduct,
  ApiProductRole,
  ApiReport,
  ApiReportingCategory,
  ApiWorkflowInternal,
  CreatedApiKey,
  FindOrganizationUserOptions,
  FindReportsOptions,
  IncludeInactiveFilter,
  SearchFilter,
  UpdateApiAccount,
  UpdateApiKey,
  UpdateApiWorkflowInternal,
} from "@operations-hero/lib-api-client";
import axios, { AxiosInstance, Method } from "axios";
import {
  ApiImportDescriptor,
  ApiImportJob,
  CreateApiImportJob,
} from "./models/Imports";
import { InternalAccountsResponse } from "./models/InternalAccountsResponse";
import { InternalOrganization } from "./models/InternalOrganization";
import { InternalUser } from "./models/InternalUser";
import { IsInternalResponse } from "./models/IsInternalResponse";

export class ApiInternalService {
  private static instance: ApiInternalService;
  private axiosInstance: AxiosInstance;

  constructor(accessToken: string) {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_INTERNAL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  public static getInstance(accessToken: string): ApiInternalService {
    if (!ApiInternalService.instance) {
      ApiInternalService.instance = new ApiInternalService(accessToken);
    }
    return ApiInternalService.instance;
  }

  public async findAccounts(params?: ApiFindAccountsOptions) {
    const url = "/accounts";
    return this.executeRequest<InternalAccountsResponse>("GET", url, params);
  }

  public async updateAccount(accountId: string, account: UpdateApiAccount) {
    const url = `/accounts/${accountId}`;
    return this.executeRequest<ApiAccountDetail>("PATCH", url, null, account);
  }

  public async findUsers(
    params?: ApiPagingOptions & { search?: string; includeInactive?: boolean }
  ) {
    const url = "/users";
    return this.executeRequest<ApiPagedResult<InternalUser>>(
      "GET",
      url,
      params
    );
  }

  public async findProducts() {
    const url = "/products";
    return this.executeRequest<ApiPagedResult<ApiProduct>>("GET", url, {
      pageSize: 100,
    });
  }

  public async isInternal() {
    const url = "/is-internal";
    return this.executeRequest<IsInternalResponse>("GET", url);
  }

  public async clearLocationCache(accountId: string) {
    const url = `/accounts/${accountId}/clear-location-cache`;
    return this.executeRequest<{ success: boolean }>("POST", url);
  }

  public async clearWorkflowPoliciesCache(accountId: string) {
    const url = `/accounts/${accountId}/clear-workflow-policies-cache`;
    return this.executeRequest<{ success: boolean }>("POST", url);
  }

  public async clearEventsPortalCache(accountId: string) {
    const url = `/accounts/${accountId}/clear-events-portal-cache`;
    return this.executeRequest<{ success: boolean }>("POST", url);
  }

  public async updateSubscription(
    accountId: string,
    subId: string,
    payload: Partial<ApiAccountSubscription>
  ) {
    const url = `/accounts/${accountId}/subscriptions/${subId}`;
    const response = this.executeRequest<ApiAccountSubscription>(
      "PATCH",
      url,
      null,
      payload
    );
    return response;
  }

  public async addSubscription(
    accountId: string,
    payload: Partial<ApiAccountSubscription>
  ) {
    const url = `/accounts/${accountId}/subscriptions`;
    const response = this.executeRequest<ApiAccountSubscription>(
      "POST",
      url,
      null,
      payload
    );
    return response;
  }

  public async deactivateSubscription(
    accountId: string,
    subscriptionId: string
  ) {
    const url = `/accounts/${accountId}/subscriptions/${subscriptionId}`;
    const response = this.executeRequest<boolean>("DELETE", url, null);
    return response;
  }

  public async reactivateSubscription(
    accountId: string,
    subscriptionId: string
  ) {
    const url = `/accounts/${accountId}/subscriptions/${subscriptionId}/reactivate`;
    const response = this.executeRequest<ApiAccountSubscription>(
      "POST",
      url,
      null
    );
    return response;
  }

  public async findNextRequestWorkflows(
    accountId: string,
    payload: ApiPagingOptions & IncludeInactiveFilter & SearchFilter
  ) {
    const url = `/accounts/${accountId}/workflows`;
    return this.executeRequest<ApiPagedResult<ApiWorkflowInternal>>(
      "GET",
      url,
      payload
    );
  }

  public async updateNextRequestWorkflow(
    accountId: string,
    workflowId: string,
    payload: UpdateApiWorkflowInternal
  ) {
    const url = `/accounts/${accountId}/workflows/${workflowId}`;
    const response = this.executeRequest<ApiWorkflowInternal>(
      "PATCH",
      url,
      null,
      payload
    );
    return response;
  }

  public async findInvoiceNumberInternal(accountId: string) {
    const url = `/accounts/${accountId}/invoices`;
    return this.executeRequest<ApiInvoiceInternal>("GET", url);
  }

  public async updateInvoiceNumberInternal(
    accountId: string,
    payload: { invoiceNumber: number }
  ) {
    const url = `/accounts/${accountId}/invoices`;
    const response = this.executeRequest<number>("PATCH", url, null, payload);
    return response;
  }

  public async mergeUsers(
    accountId: string,
    payload: { target: string; source: string[] }
  ) {
    const url = `/accounts/${accountId}/users/merge-users`;
    const response = this.executeRequest<ApiWorkflowInternal>(
      "POST",
      url,
      null,
      payload
    );
    return response;
  }

  public async findApiKeys(
    page: ApiPagingOptions & SearchFilter & IncludeInactiveFilter
  ) {
    const url = `/api-keys`;
    const response = this.executeRequest<ApiPagedResult<ApiKey>>(
      "GET",
      url,
      page
    );
    return response;
  }

  public async getApiKeyDetail(apiKeyId: string) {
    const url = `/api-keys/${apiKeyId}`;
    const response = this.executeRequest<ApiKey>("GET", url);
    return response;
  }

  public async updateApiKey(apiKeyId: string, apiKey: UpdateApiKey) {
    const url = `/api-keys/${apiKeyId}`;
    const response = this.executeRequest<ApiKey>(
      "PATCH",
      url,
      undefined,
      apiKey
    );
    return response;
  }

  public async createApiKey(apiKey: {
    name: string;
    accountIds: string[];
  }): Promise<CreatedApiKey> {
    const url = `/api-keys`;
    const response = await this.executeRequest<CreatedApiKey>(
      "POST",
      url,
      null,
      apiKey
    );

    return response;
  }

  public async getApiKeyId(apiKeyId: string): Promise<ApiKey> {
    const url = `/api-keys/${apiKeyId}`;
    const response = await this.executeRequest<ApiKey>("GET", url, null);
    return response;
  }

  public async deleteApiKey(apiKeyId: string) {
    const url = `/api-keys/${apiKeyId}`;
    const response = this.executeRequest<boolean>("DELETE", url);
    return response;
  }

  public async addKeyAccounts(apiKeyId: string, accounts: string[]) {
    const url = `/api-keys/${apiKeyId}/accounts`;
    return this.executeRequest<InternalOrganization>(
      "POST",
      url,
      null,
      accounts
    );
  }

  public async createITCategories(accountId: string) {
    const url = `/accounts/${accountId}/reporting-categories/create-it-categories`;
    const response = this.executeRequest<ApiReportingCategory[]>(
      "POST",
      url,
      null
    );
    return response;
  }

  public async checkITCategories(accountId: string) {
    const url = `/accounts/${accountId}/reporting-categories/check-it-categories`;
    const response = this.executeRequest<boolean>("GET", url, null);
    return response;
  }

  public async forceLogout(payload: { userId: string }) {
    const url = `/users/${payload.userId}/force-logout`;
    const response = this.executeRequest<ApiWorkflowInternal>(
      "GET",
      url,
      null,
      payload
    );
    return response;
  }

  public async findOrganizations(
    params?: ApiPagingOptions & { search?: string }
  ) {
    const url = "/organizations";
    return this.executeRequest<InternalOrganization>("GET", url, params);
  }

  public async createOrganization(name: string) {
    const url = "/organizations";
    return this.executeRequest<InternalOrganization>("POST", url, null, {
      name: name,
    });
  }

  public async updateOrganization(organizationId: string, name: string) {
    const url = `/organizations/${organizationId}`;
    return this.executeRequest<InternalOrganization>("PATCH", url, null, {
      name: name,
    });
  }

  public async findOrganizationAccounts(
    organizationId: string,
    payload?: ApiPagingOptions & SearchFilter
  ) {
    const url = `/organizations/${organizationId}/accounts`;
    return this.executeRequest<ApiPagedResult<ApiAccountDetail>>(
      "GET",
      url,
      payload
    );
  }

  public async addOrganizationAccounts(
    organizationId: string,
    accounts: string[]
  ) {
    const url = `/organizations/${organizationId}/accounts`;
    return this.executeRequest<InternalOrganization>(
      "POST",
      url,
      null,
      accounts
    );
  }

  public async removeOrganizationAccounts(
    organizationId: string,
    accounts: string[]
  ) {
    const url = `/organizations/${organizationId}/accounts`;
    return this.executeRequest<InternalOrganization>(
      "DELETE",
      url,
      null,
      accounts
    );
  }

  public getOrganizationUsers(
    organizationId: string,
    options?: FindOrganizationUserOptions
  ) {
    const url = `/organizations/${organizationId}/users`;
    return this.executeRequest<ApiPagedResult<ApiAccountUser>>(
      "GET",
      url,
      options
    );
  }

  public updateOrganizationUserRole(
    organizationId: string,
    userId: string,
    userProducts: ApiProductRole[]
  ) {
    const url = `/organizations/${organizationId}/users/${userId}`;
    return this.executeRequest<ApiPagedResult<ApiAccountUser>>(
      "PATCH",
      url,
      null,
      { products: [...userProducts] }
    );
  }

  public deactivateOrganizationUserRole(
    organizationId: string,
    userId: string
  ) {
    const url = `/organizations/${organizationId}/users/${userId}`;
    return this.executeRequest<ApiPagedResult<ApiAccountUser>>(
      "DELETE",
      url,
      null
    );
  }

  public async updateAccountOwner(accountId: string, newOwnerId: string) {
    const url = `/accounts/${accountId}/update-owner`;
    return this.executeRequest<ApiAccountDetail>("PUT", url, null, {
      newOwnerId,
    });
  }

  public async getDashboards() {
    const url = `/reports/dashboards`;
    const response = await this.executeRequest<ApiDashboard[]>(
      "GET",
      url,
      null
    );
    return response;
  }

  public async createReport(
    accountId: string,
    payload: { dashboardId: string; productId: string }
  ) {
    const url = `/accounts/${accountId}/reports`;
    return this.executeRequest<ApiReport>("POST", url, null, payload);
  }

  public async findReports(accountId: string, options: FindReportsOptions) {
    const url = `/accounts/${accountId}/reports`;
    const response = await this.executeRequest<{
      options: FindReportsOptions;
      data: ApiReport[];
      total: number;
    }>("GET", url, options);
    return response;
  }

  public async clearReportsCache(accountId: string) {
    const url = `/accounts/${accountId}/clear-reports-cache`;
    return this.executeRequest<{ success: boolean }>("POST", url);
  }

  public async clearVenuesCache(accountId: string) {
    const url = `/accounts/${accountId}/clear-venues-cache`;
    return this.executeRequest<{ success: boolean }>("POST", url);
  }

  public async deactivateReport(accountId: string, reportId: string) {
    const url = `/accounts/${accountId}/reports/${reportId}`;
    const response = this.executeRequest<boolean>("DELETE", url, null);
    return response;
  }

  public async reactivateReport(accountId: string, reportId: string) {
    const url = `/accounts/${accountId}/reports/${reportId}/reactivate`;
    const response = this.executeRequest<ApiAccountSubscription>(
      "POST",
      url,
      null
    );
    return response;
  }

  public async getEventSummary(accountId: string) {
    const url = `/accounts/${accountId}/events/summary`;
    const response = this.executeRequest<ApiAccountEventSummary>("GET", url);
    return response;
  }

  public async updateEventCounter(accountId: string, newValue: number) {
    const url = `/accounts/${accountId}/event-counter`;
    const response = this.executeRequest<number>("POST", url, undefined, {
      eventNumber: newValue,
    });
    return response;
  }

  /* imports */
  public async findImports(accountId: string, params?: ApiPagingOptions) {
    const url = `/accounts/${accountId}/imports`;
    return this.executeRequest<ApiPagedResult<ApiImportJob>>(
      "GET",
      url,
      params
    );
  }

  public async createImport(accountId: string, job: CreateApiImportJob) {
    const url = `/accounts/${accountId}/imports`;
    return this.executeRequest<ApiImportJob>("POST", url, undefined, job);
  }

  public async retryImport(accountId: string, importId: string) {
    const url = `/accounts/${accountId}/imports/${importId}/retry`;
    return this.executeRequest<ApiImportJob>("POST", url);
  }

  public async findImportDefinitions() {
    const url = `/import-definitions`;
    return this.executeRequest<ApiImportDescriptor[]>("GET", url);
  }

  /* helpers */
  private async executeRequest<T>(
    method: Method,
    url: string,
    params?: Record<string, any> | null,
    data?: Record<any, any>
  ) {
    const result = await this.axiosInstance({ method, url, params, data });
    return result.data as T;
  }
}
