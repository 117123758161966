import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ApiKey } from "@operations-hero/lib-api-client";
import React from "react";
import { useShowToast } from "../../hooks/showToast";
import { useAuthentication } from "../../components/auth/AuthProvider";

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedKey: ApiKey | undefined;
  fetchApiKeys: () => void;
}

export const DeleteConfirmation: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  onClose,
  selectedKey,
  fetchApiKeys,
}) => {
  const toast = useShowToast();
  const { apiInternal } = useAuthentication();
  const handleOnClickDeleteApiKey = () => {
    if (selectedKey === undefined) return;
    apiInternal
      .deleteApiKey(selectedKey?.id)
      .then(() => {
        fetchApiKeys();
        toast("success", "Key Deleted");
        onClose();
      })
      .catch(() =>
        toast("error", "Failed to delete key, please try again later")
      );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to delete this key?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Key Name: {selectedKey?.name}</Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleOnClickDeleteApiKey}>
            Delete Key
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
