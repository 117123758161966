import { Container, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Accounts } from "../../pages/accounts-list/Accounts";
import { Account } from "../../pages/accounts/Account";
import { Organizations } from "../../pages/organization-list/Organizations";
import { Organization } from "../../pages/organizations/Organization";
import { ApiKeys } from "../../pages/api-keys/KeyList";
import { ApiKey } from "../../pages/api-keys-list/ApiKeyList";
import { Users } from "../../pages/users/Users";
import { NavBar } from "./nav/NavBar";

export const AppShell: FC = () => {
  return (
    <Flex flexDir="column" w="100%" h="100vh" minW="320px">
      <Container maxW="8xl">
        <NavBar />
        <Flex as="main" py={4}>
          <Routes>
            <Route path="/" element={<Accounts />} />
            <Route path="/accounts/:acctId" element={<Account />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/users" element={<Users />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/organizations/:orgId" element={<Organization />} />
            <Route path="/api-keys" element={<ApiKeys />} />
            <Route path="/api-keys-list/:keyId" element={<ApiKey />} />
          </Routes>
        </Flex>
      </Container>
    </Flex>
  );
};
