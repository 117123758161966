import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { useShowToast } from "../../hooks/showToast";

interface CreateConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenCpy: () => void;
  fetchApiKeys: () => void;
}

export const CreateConfirmation: React.FC<CreateConfirmationModalProps> = ({
  isOpen,
  onClose,
  onOpenCpy,
  fetchApiKeys,
}) => {
  const toast = useShowToast();
  const { apiInternal } = useAuthentication();
  const [selectedKeyName, setSelectedKeyName] = React.useState<string>("");

  // Create Key
  const handleOnClickCreateApiKey = useCallback(() => {
    if (selectedKeyName === "") return;

    apiInternal
      .createApiKey({ name: selectedKeyName, accountIds: [] })
      .then(() => {
        onOpenCpy();
        fetchApiKeys();
        toast("success", "API Key Created");
        onClose();
      })
      .catch(() => {
        toast("error", "Failed to create API Key");
      });
  }, [toast, apiInternal, selectedKeyName, onClose, onOpenCpy, fetchApiKeys]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h3>Key Name</h3>
            <Input
              isRequired={true}
              title="Key Name"
              onChange={(event) => setSelectedKeyName(event.target.value)}
              placeholder="Name"
              marginBottom={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleOnClickCreateApiKey}
            >
              Confirm
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
