import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiKey } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { UseTableCellProps } from "react-table";
import { useAuthentication } from "../../components/auth/AuthProvider";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { RootState, useThunkDispatch } from "../../store";
import { CopyConfirmation } from "./CopyConfirmation";
import { CreateConfirmation } from "./CreateConfirmation";
import { DeleteConfirmation } from "./DeleteComfirmation";

import { useNavigate } from "react-router-dom";
import {
  initApiKeys,
  setKey,
  updateFilters,
} from "../../store/keys/keys.slice";
import { EditApiKeyNameModal } from "./EditApiKeyNameModal";

const ApiKeyList: FC = () => {
  const history = useNavigate();
  const { apiInternal } = useAuthentication();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCpy,
    onOpen: onOpenCpy,
    onClose: onCloseCpy,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();

  //Api Key States
  const [apiKeysList, setApiKeysList] = useState<ApiKey[]>([]);
  const [selectedKey, setSelectedKey] = useState<ApiKey>();
  //Pagnation States

  const thunkDispatch = useThunkDispatch();

  const tableHeaderBg = useColorModeValue("blue.50", "transparent");
  const dispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.keys);

  const { total, page, pageSize } = filters;

  const fetchApiKeys = useCallback(async () => {
    const keyArray = await apiInternal.findApiKeys({
      search: filters.search,
      page: page,
      pageSize: pageSize,
    });
    setApiKeysList(keyArray.data);
  }, [filters.search, page, apiInternal, pageSize]);

  const handleRedirection = useCallback(
    (rowData) => {
      setSelectedKey(rowData);
      dispatch(setKey(rowData));
      history(`/api-keys-list/${rowData.id}`);
    },
    [history, dispatch]
  );

  const onPageChange = useCallback(
    (newPage: number) => {
      dispatch(updateFilters({ page: newPage }));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchApiKeys();
    thunkDispatch(initApiKeys({ apiInternal }));
  }, [fetchApiKeys, thunkDispatch, apiInternal, filters]);

  const columns = useMemo<DataTableColumn<ApiKey>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: UseTableCellProps<ApiKey>) => {
          return <Text isTruncated>{data.row.original.name}</Text>;
        },
      },
      {
        Header: "Created",
        Cell: (data: UseTableCellProps<ApiKey>) => {
          return (
            <Text isTruncated>
              {new Date(data.row.original.created).toLocaleDateString()}
            </Text>
          );
        },
      },
      {
        Header: "# Accounts",
        Cell: (data: UseTableCellProps<ApiKey>) => {
          return <Text isTruncated>{data.row.original.accounts.length}</Text>;
        },
      },
      {
        Header: "Actions",
        Cell: (data: UseTableCellProps<ApiKey>) => {
          return (
            <>
              <Spacer />
              <Box justifyItems="right" justifyContent="flex-end">
                <HStack justifyContent="flex-end">
                  <Icon
                    cursor="pointer"
                    boxSize="24px"
                    as={MdEdit}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenUpdate();
                      setSelectedKey(data.row.original);
                    }}
                    color={"blue.500"}
                    marginRight={4}
                  />
                  <Button
                    size="sm"
                    colorScheme="red"
                    variant="solid"
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenDelete();
                      setSelectedKey(data.row.original);
                    }}
                  >
                    Deactivate
                  </Button>
                </HStack>
              </Box>
            </>
          );
        },
      },
    ],
    [onOpenUpdate, onOpenDelete]
  );

  return (
    <Stack w="100%">
      <Flex w="100%" justifyContent="space-between">
        <Flex alignItems="center" justifyContent="space-between" w="full">
          <Heading size="lg" w="max-content" isTruncated>
            Api Keys
          </Heading>
          <Button
            colorScheme="blue"
            variant="solid"
            alignSelf="flex-start"
            maxWidth="200px"
            width="100%"
            onClick={() => onOpen()}
          >
            Activate New Key
          </Button>
        </Flex>
      </Flex>

      <CreateConfirmation
        isOpen={isOpen}
        onClose={onClose}
        onOpenCpy={onOpenCpy}
        fetchApiKeys={fetchApiKeys}
      />

      <EditApiKeyNameModal
        isOpen={isOpenUpdate}
        onClose={onCloseUpdate}
        selectedKey={selectedKey}
        fetchApiKeys={fetchApiKeys}
      />

      <CopyConfirmation
        isOpen={isOpenCpy}
        onClose={onCloseCpy}
        selectedKey={selectedKey}
      />

      <DeleteConfirmation
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        selectedKey={selectedKey}
        fetchApiKeys={fetchApiKeys}
      />

      <Flex w="100%" justifyContent="space-between">
        <Input
          placeholder="Search"
          w="30%"
          onChange={(e) => {
            dispatch(updateFilters({ search: e.target.value }));
          }}
        />
        <Spacer />
      </Flex>
      <PageData total={total} currentPage={page} pageSize={pageSize} />
      <DataTable
        columns={columns}
        data={apiKeysList}
        rest={{ w: "100%" }}
        onRowClick={handleRedirection}
        tableHeaderProps={{ p: 2, bgColor: tableHeaderBg, borderRadius: 6 }}
      />

      <Flex flexWrap="wrap" justifyContent="space-between">
        <PageData total={total} currentPage={page} pageSize={pageSize} />

        <Pager
          currentPage={page}
          totalPages={Math.ceil(total / pageSize)}
          onPageChange={onPageChange}
        />
      </Flex>
    </Stack>
  );
};

export const ApiKeys: FC = () => {
  return <ApiKeyList />;
};
