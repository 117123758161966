import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ApiKey } from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useShowToast } from "../../hooks/showToast";

interface CopyConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedKey: ApiKey | undefined;
}

export const CopyConfirmation: React.FC<CopyConfirmationModalProps> = ({
  isOpen,
  onClose,
  selectedKey,
}) => {
  const toast = useShowToast();
  const [copiedKey, setCopiedKey] = useState(selectedKey);
  const handleOnClickCpy = useCallback(() => {
    navigator.clipboard.writeText(String(copiedKey?.id)).then(
      () => {
        toast("success", "Copied to clipboard");
        onClose();
      },
      () => {
        toast("error", "Failed to copy to clipboard");
      }
    );
  }, [toast, onClose, copiedKey]);

  useEffect(() => {
    if (selectedKey) {
      setCopiedKey(selectedKey);
    }
  }, [selectedKey]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remember to copy your key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{copiedKey?.id}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleOnClickCpy}>
              <MdContentCopy />
              Copy Key
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
