import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { ApiKey } from "@operations-hero/lib-api-client";
import React, { useEffect, useState } from "react";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { useShowToast } from "../../hooks/showToast";
interface UpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedKey: ApiKey | undefined;
  fetchApiKeys: () => void;
}

export const EditApiKeyNameModal: React.FC<UpdateModalProps> = ({
  isOpen,
  onClose,
  selectedKey,
  fetchApiKeys,
}) => {
  const toast = useShowToast();
  const { apiInternal } = useAuthentication();
  const [nameState, setNameState] = useState(selectedKey?.name);

  const handleInputChange = (event: any) => {
    setNameState(event.target.value);
  };

  const handleOnClickUpdateApiKey = () => {
    if (!selectedKey) return;
    apiInternal
      .updateApiKey(selectedKey.id, { name: nameState })
      .then(() => {
        fetchApiKeys();
        toast("success", "Successfully updated key name");
        onClose();
      })
      .catch((err) => {
        toast("error", "Failed to update key name");
      });
  };

  useEffect(() => {
    if (selectedKey) {
      setNameState(selectedKey.name);
    }
  }, [selectedKey]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Key Name</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <h3>Key Name</h3>
          <Input
            title="Key Name"
            value={nameState}
            onChange={handleInputChange}
            marginBottom={4}
          />
        </ModalBody>

        <ModalFooter>
          <VStack gap={4} p={2}>
            <HStack justifyContent="space-between" width="100%">
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                onClick={handleOnClickUpdateApiKey}
              >
                Submit
              </Button>
            </HStack>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
