import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Stack,
  StackItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { AccountMenu } from "./AccountMenu";
import { Logo } from "./Logo";

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Accounts",
    href: "/accounts",
  },
  {
    label: "Users",
    href: "/users",
  },
  {
    label: "Organizations",
    href: "/organizations",
  },
  {
    label: "Api Keys",
    href: "/api-keys",
  },
];

export const NavBar = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        minH={"60px"}
        py={{ base: 2 }}
        align={"center"}
        borderBottom="1px solid"
        color={useColorModeValue("gray.600", "white")}
        bg={useColorModeValue("white", "gray.800")}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <Flex
          ml={{ base: -2 }}
          flex={{ base: 1, md: "auto" }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Logo />
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Flex flex={{ base: 1, md: 0 }} justify={"flex-end"} direction={"row"}>
          <AccountMenu />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

const DesktopNav: FC = () => {
  return (
    <Stack direction={"row"} spacing={4} align="center">
      {NAV_ITEMS.map((navItem) => (
        <StackItem key={navItem.label}>
          <Link color="red" to={navItem.href}>
            {navItem.label}
          </Link>
        </StackItem>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      mt={2}
      direction={"row"}
    >
      {NAV_ITEMS.map((navItem, index) => (
        <StackItem key={navItem.label}>
          <Link to={navItem.href}>{navItem.label}</Link>
        </StackItem>
      ))}
    </Stack>
  );
};
